import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Overview from "./Overview";
import Dimension from "./Dimension";
import { useSearchParams, useLocation } from "react-router-dom";
import Feedback from "./Feedback/index";
import AI from "../AI/index"
import Welcome from './welcome'

import SurveyVersions from "./VersionControl/index";

// import Individual from "./Individual";
// import Grid from "./Grid";
// import GridTime from "./GridTime";
// import Table from "./Table";
// import NavBar from "./_NavBar";
// import Demographics from "./Demographics2";
// import Maps from "./Map/Maps";

import NavBar2 from "./NavBar2";
import SubNav from "./SubNav";
import History from "./History";
// import Demographics from "./Demographics2";
// import Maps from "./Map/Maps";
import Anchor from "./Anchor";
import { Data_GeneratorV2 } from "./Testing/generate_data";
import Empty from "./Empty";
import CustomNav from "./CustomNav";
import FilterContainer from "./Navigation/Filters";
import Pillar from './Pillar'
import Insights from './Insights'
import Help from '../Help'


// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import useGetUserSettings from "hooks/useGetUserSettings";
import Languages from 'language/index'
import {useLanguageSetting} from 'language/redux_store'

import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';
import Checklist from './Checklist'

// Calculations
import {
  filter_data,
  get_dates,
  get_most_recent,
  sort_historical_data,
  // calculate_table_data,
} from "./data_navigation/calculate_data";

// import full_data from "./Testing/data.json";
// import survey_structure from "./survey_structure_blades.json";
// import question_structure from "./question_structure_2.json";

import {
  SET_FILTERED_DATA,
  SET_FILTERED_LAST_DATA,
  SET_DATA_DATES,
  SET_CATEGORY_DATA,
  SET_RAW_DATA,
} from "constants/actions";



const Audit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDEI = location.pathname.includes("edi");
  let [searchParams, setSearchParams] = useSearchParams();
  let testing = searchParams.get("testing");
  // let ai = searchParams.get("ai");
  const [loading, setLoading] = useState(true);
  const [reportData2, setReportData] = useState([]);
  const [category_access, setAccess] = useState([]);
  const [subfactor_access, setSubAccess] = useState(false);
  const [selected, setSelected] = useState(1);
  const language_hook = new Languages(useLanguageSetting())
  const [catLoad, setCat] = useState(false);
  const [rule, setRule] = useState(2);


  const { sub_state, nav_state,survey_version } = useSelector(
    (state) => ({
      sub_state: state.audit.sub,
      nav_state: state.audit.nav,
      survey_version: state.audit.survey_version,
    }),
    shallowEqual
  );




  const { filteredCategories, isAdmin } = useGetUserSettings();
 
  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
    standards
  } = useGetSurveyData({
    surveyType: isDEI ? 7 : 6,
    auditTypeId: isDEI ? 4 : 3,
    filteredCategories,
    isAdmin,
    survey_version
  });

  console.log(get_survey_structure)


  const { questionStructure, reportData, surveyComments } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
    surveyType: isDEI ? 7 : 6,
    filteredCategories,
    isAdmin,
    survey_version
  });


  const [comments, setComments] = useState(surveyComments);

  useEffect(() => {
    setComments(surveyComments);
  }, [surveyComments]);

  useEffect(() => {

    if (get_employee.length < 1) {
      return;
    }
    let role =get_employee?.userEmp?.account_type_text == "Admin";
    if (get_employee.userEmp.employee_employee_category?.category.length > 0 && !role) {
      setAccess(
       get_employee.userEmp.employee_employee_category.category
      );
    }

    setSubAccess(
      get_employee.userEmp.access_subfactor_data
    );
  }, [get_auth, get_employee_categories]);

  useEffect(() => {
    if (Object.keys(WhiteLabel).includes("rule")) {
      setRule(WhiteLabel.rule);
    }
  }, [WhiteLabel]);

  useEffect(() => {
    if (nav_state && reportData) {
      if (Object.keys(nav_state).length > 0) {
        if (reportData2.length > 0) {
          let filtered_data = filter_data(reportData2, nav_state,get_survey_structure[0])
          dispatch({
            type: SET_FILTERED_DATA,
            payload: filtered_data.filter_data,
          });

          dispatch({
            type: SET_FILTERED_LAST_DATA,
            payload: filtered_data.filter_data_last,
          });
        } else {
          let filtered_data = filter_data(reportData, nav_state,get_survey_structure[0])
          dispatch({
            type: SET_FILTERED_DATA,
            payload: filtered_data.filter_data,
          });

          dispatch({
            type: SET_FILTERED_LAST_DATA,
            payload: filtered_data.filter_data_last,
          });
        }
      }
    }
  }, [dispatch, nav_state, reportData, reportData2]);

  useEffect(() => {
    if (get_survey_structure) {
      dispatch({
        type: SET_CATEGORY_DATA,
        payload: get_survey_structure[0],
      });
      setTimeout(() => setCat(true), 1000);
    }
  }, [dispatch, get_survey_structure]);

  const convert_data = (data) => {
    let _cultureAuditResponse = [];
    data.response.shift();
    _cultureAuditResponse = data.response.map((item, i) => {
      if (item?.responses?.length > 0) {
        item?.responses.map((sub) => {
          const _QuesResponses = Object.values(sub?.response?.question);
          const _Questions = arrange_Questions(
            get_survey_questions?.[0]?.questions
          );
          const _QueResp = [];
          _QuesResponses.map((re, i) => {
            _QueResp.push({
              id: _Questions[i]?.dimension,
              factor: _Questions[i]?.factor,
              q: _Questions[i]?.q,
              response: re,
            });
          });

          const _CatResponses = Object.values(sub?.response?.category);
          const _Category = get_survey_structure?.[0]?.categories;
          const _CatResp = [];

          const primary_id = _Category.find((f) => f.priority == "primary").id;
          _CatResp.push({
            id: primary_id,
            response: _Category
              .find((f) => f.priority == "primary")
              .options.findIndex((e) => e.name == item.name),
            subfactor: null,
            priority: "primary",
          });

          _CatResponses.map((re, i) => {
            _CatResp.push({
              id: _Category[i]?.id,
              response: 0,
              subfactor: null,
              priority: _Category[i]?.priority,
            });
          });
          sub["response"]["question"] = _QueResp;
          sub["response"]["category"] = _CatResp;

          return null;
          //  {sub.response.question: _QueResp}
        });
      }
      return item;
    });

    _cultureAuditResponse = _cultureAuditResponse.filter(
      (f) => f.responses.length > 0
    );

    return _cultureAuditResponse;
  };

  useEffect(() => {
    console.log(nav_state)
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }, [nav_state ]);


  useEffect(()=>{
        setLoading(true);
    setSelected(1)
    setTimeout(() => setLoading(false), 1000);
  },[sub_state])

  const generate_test_data_set = () => {
    setLoading(true);
    const DataGenerated = Data_GeneratorV2(
      500,
      10,
      get_survey_structure[0],
      questionStructure
    );

    let most_recent = get_most_recent(DataGenerated);
    let historical_data = sort_historical_data(DataGenerated.reverse(), 0);

  

    const check_comments = (resp) => {
      let comm = false;
      for (var i = 0; i < resp.responses.length; i++) {
        if (
          "comments" in resp.responses[i] ||
          "feedback" in resp.responses[i]
        ) {
          comm = true;
          break;
        }
      }

      return comm;
    };

    setComments(check_comments(most_recent));
    setReportData([most_recent, ...historical_data]);

    dispatch({
      type: SET_RAW_DATA,
      payload: [most_recent, ...historical_data],
    });

    dispatch({
      type: SET_DATA_DATES,
      payload: get_dates(historical_data),
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (reportData) {
      setLoading(false);
    }
  }, [reportData, sub_state]);

  if (!catLoad || !nav_state) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader active inline>
          Loading
        </Loader>
      </div>
    );
  }

  if (reportData.length === 0 && reportData2.length === 0) {
    return (
      <>
        {testing ? (
          <Test onClick={() => generate_test_data_set()} id={"test"}>
            Generate Data
          </Test>
        ) : (
          ""
        )}
        <Empty language_hook={language_hook}/>
      </>
    );
  }

  const generate_content = () => {
    if (sub_state === 1) {
      console.log(reportData,'I am here')
      return (
        <Overview
          nav={nav_state}
          data={reportData2.length > 0 ? reportData2 : reportData}
          // refresh={render}
          structure={get_survey_structure[0]}
          questions={questionStructure}
          WhiteLabel={WhiteLabel}
          rule={rule}
          selected={selected}
          standards={standards}
        />
      );
    }
    if (sub_state === 2) {
      return (
        <History
          WhiteLabel={WhiteLabel}
          structure={questionStructure}
          rule={rule}
          nav={nav_state}
          is_french={language_hook.is_french()}
          language_hook={(w) => language_hook.fetch_words(w)}
        />
      );
    }
    if (sub_state === 3) {
      return (
        <Dimension
          data={reportData2.length > 0 ? reportData2 : reportData}
          nav={nav_state}
          // refresh={render}
          WhiteLabel={WhiteLabel}
          structure={questionStructure}
          categories={get_survey_structure[0]}
          rule={rule}
          selected={selected}
          is_french={language_hook.is_french()}
          language_hook={(w) => language_hook.fetch_words(w)}
        />
      );
    }
    if (sub_state === 5) {
      return (
        <Pillar
          data={reportData2.length > 0 ? reportData2 : reportData}
          nav={nav_state}
          // refresh={render}
          WhiteLabel={WhiteLabel}
          structure={questionStructure}
          categories={get_survey_structure[0]}
          rule={rule}
          selected={selected}
          standards={standards}
          language_hook={(w)=>language_hook.fetch_words(w)}
        />
      );
    }

    if (sub_state === 6) {
      return <Insights questions={questionStructure} />;
    }

    //  if (sub_state === 7) {
    //   return (
    //     <AI data={reportData} structure={questionStructure}/>
    //   );
    // }

    return (
      <Feedback
        WhiteLabel={WhiteLabel}
        data={reportData}
        structure={questionStructure}
        categories={get_survey_structure[0]}
        selected2={selected}
        setTab={(v) => setSelected(v)}
        userEmp={get_employee.userEmp}
        is_french={language_hook.is_french()}
        language_hook={(w) => language_hook.fetch_words(w)}
      />
    );
  };

  return (
    <>
      <Container>
        {testing ? (
          <Test onClick={() => generate_test_data_set()} id={"test"}>
            Generate Data
          </Test>
        ) : (
          ""
        )}
        <SubNav
          WhiteLabel={WhiteLabel}
          comments={comments}
          questionStructure={questionStructure}
          ai={true}
        />

        
        <NavBarContainer>
          <div style={{ display: "flex" }}>
            <NavBar2
              structure={get_survey_structure[0]}
              subfactor_access={subfactor_access}
              employee_categories={category_access}
              isAdmin={get_employee.userEmp.account_type_text == "Admin"}
            />
            <CustomNav
              options={
                isDEI
                  ? customNavStates2[sub_state - 1]
                  : customNavStates[sub_state - 1]
              }
              selected={selected}
              setSelected={(v) => setSelected(v)}
              language_hook={(w)=>language_hook.fetch_words(w)}
            />
            <div
              style={{ position: "absolute", right: 0, top: 10, width: 300,display:'flex',justifyContent:'space-between' }}
            >
              <SurveyVersions/>

              <Anchor is_french={language_hook.is_french()}/>
            </div>
          </div>

          <FilterContainer
            structure={get_survey_structure[0]}
            employee_categories={category_access}
          />
        </NavBarContainer>

        <Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          {generate_content()}
        </Dimmer.Dimmable>

      </Container>
      <MobileContainer>
        Only available on displays wider than 600px
      </MobileContainer>
    </>
  );
};

const arrange_Questions = (questions) => {
  // console.log(questions)
  if (questions) {
    return questions.dimension
      .map((item, i) => {
        return item.factors.map((factor, f) => {
          return factor.questions.map((q, idx) => {
            return {
              question: q.q,
              dimension: i,
              factor: f,
              q: idx,
              role: q.role,
              reverse: q.reverse,
              order: q.id,
            };
          });
        });
      })
      .flat()
      .flat()
      .sort((a, b) => a.order - b.order);
  }
};

const Container = styled.div`
  font-family: "Red Hat Display", sans-serif;
  padding: 10px 0px;
  position:relative;

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Test = styled.button`
  margin-top: 10px;
`;

const NavBarContainer = styled.div`
  position: relative;
  padding-left: 58px;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const customNavStates = [
  ["Culture Map", "Heat Map"],
  [],
  ["Table Data", "Raw Data"],
  ["Context", "Questions"],
  [],
  [],
  []
];

const customNavStates2 = [
  ["DEI Map", "Heat Map"],
  [],
  ["Table Data", "Raw Data"],
  ["Context", "Questions"],
  [],
  [],
  []
];

export default Audit;
