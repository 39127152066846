import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  Icon,
  Progress,
  Dropdown,
  Tab,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Languages from "language/index";
import { useLanguageSetting } from "language/redux_store";
import { SET_SELECTED_QUESTION } from "constants/actions";

const QuestionDrop = ({ structure, color, pillarOnly }) => {
  const language_hook = new Languages(useLanguageSetting());
  const isFrench = language_hook.is_french();
  const dispatch = useDispatch();
  let ids = 0;
  const setStructure = () => {
    let names = [];
    if (!pillarOnly) {
      names.push({
        title: isFrench ? "Culture Générale" : "Overall DEI",
        level: 0,
        id: ids,
      });
      ids += 1;
    }

    structure.dimensions.map((item, i) => {
      if (!pillarOnly) {
        names.push({
          title: isFrench ? (item.fr ? item.fr : item.title) : item.title,
          level: 1,
          id: ids,
          dimension: i,
        });
        ids += 1;
      }

      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: isFrench ? (x.fr ? x.fr : x.title) : x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
    return names;
  };

  const [hoveredItem, setHoveredItem] = useState();
  const [labels, setLables] = useState(setStructure());
  const [groupedLabels, setGroupedLabels] = useState([]);
  const [parentLabels, setParentLabels] = useState([]);
  const [selected, setSelected] = useState();

  const question = useSelector((state) => state.audit?.selected, shallowEqual);


  const { selected: selectedQuestion } = useSelector(
    (state) => ({
      selected: state.audit.selected,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (selectedQuestion) {
      select(selectedQuestion);
    }
  }, [selectedQuestion]);

  const select = (d) => {
    setSelected(d);
    dispatch({
      type: SET_SELECTED_QUESTION,
      payload: d,
    });
  };

  useEffect(() => {
    if (selectedQuestion?.id === 0 && selectedQuestion?.id === 0) {
      if (pillarOnly) {
        select(labels.filter((f) => f.level === 2)[0]);
      } else if (labels) {
        select(labels[0]);
      }
    }
  }, [labels]);

  useEffect(() => {
    const groups = getGroupedLabels(labels);
    const labelGroups = Object.keys(groups);
    setGroupedLabels(getGroupedLabels(labels));
    setParentLabels(labelGroups);
  }, [labels]);

  const get_label = () => {
    labels.filter((f) => f.level == question.level && f.id == question.id);
  };

  const getGroupedLabels = (labels) => {
    return labels.reduce((acc, item) => {
      if (item.level === 1) {
        acc[item.title] = { ...item, factors: [] };
      }
      if (item.level === 2) {
        const parent = labels.find(
          (parent) => parent.dimension === item.dimension
        );
        if (parent) {
          acc[parent.title] = acc[parent.title] || [];
          if (acc[parent.title]?.factors) {
            acc[parent.title].factors.push(item);
          } else {
            acc[parent.title].factors = [item];
          }
        }
      }
      return acc;
    }, []);
  };

  return (
    <First>
      <Dropdown
        text={
          labels.find(
            (f) =>
              f.dimension === question.dimension && f.factor === question.factor
          )?.title
        }
        multiple
        icon="angle down"
        id={"questions"}
      >
        <Dropdown.Menu>
          <SelectWrapper style={{ paddingTop: 20, paddingBottom: 10 }}>
            <Dropdown.Item
              style={{
                marginBottom: 5,
                fontWeight: "bold",
                marginLeft: "20px",
              }}
              onClick={() => select(labels[0])}
              id={"questions"}
            >
              <SelectItem
                style={{
                  backgroundColor:
                    hoveredItem === "parent" ? "#F8FAFF" : "#fff",
                }}
              >
                <ItemContainer
                  onMouseOver={() => setHoveredItem(`parent`)}
                  onMouseLeave={() => setHoveredItem("")}
                >
                  ALL CULTURE FACTORS
                  {selected?.title === labels[0].title && (
                    <Icon
                      style={{ marginTop: -15, paddingLeft: 6 }}
                      name="check"
                    />
                  )}
                </ItemContainer>
              </SelectItem>
            </Dropdown.Item>
          </SelectWrapper>
          <SelectWrapper style={{ paddingBottom: 20 }}>
            {parentLabels.map((item, i) => {
              const isHovered = hoveredItem === `dimension${i}`;
              const parentSelected =
                selected?.title === groupedLabels[item].title;
              const dimensionSelected = selected?.title === labels[0].title;

              return (
                <FactorSection>
                  <Dropdown.Item
                    style={{
                      marginBottom: 5,
                      fontWeight: "bold",
                    }}
                    onClick={() => select(groupedLabels[item])}
                    id={"dimension" + i}
                  >
                    <SelectItem style={{ paddingBottom: 10 }}>
                      <ItemContainer
                        style={{
                          paddingBottom: 10,
                          backgroundColor: isHovered ? "#F8FAFF" : "#fff",
                        }}
                        onMouseOver={() => setHoveredItem(`dimension${i}`)}
                        onMouseLeave={() => setHoveredItem("")}
                      >
                        {item}
                        {(parentSelected || dimensionSelected) && (
                          <Icon
                            style={{ marginTop: -15, paddingLeft: 6 }}
                            name="check"
                          />
                        )}
                      </ItemContainer>
                    </SelectItem>
                  </Dropdown.Item>
                  {groupedLabels[item].factors.map((factor, idx) => {
                    const isSelected = selected?.title === factor.title;
                    const allSelected = selected?.title === labels[0].title;
                    const parentSelected =
                      selected?.title === groupedLabels[item].title;

                    const isHovered = hoveredItem === `questions${idx}`;
                    return (
                      <Dropdown.Item
                        onClick={() => select(factor)}
                        id={"questions" + idx}
                        key={idx}
                      >
                        <ItemContainer
                          style={{
                            fontSize: 13,
                            backgroundColor: isHovered ? "#F8FAFF" : "#fff",
                          }}
                          onMouseOver={() => setHoveredItem(`questions${idx}`)}
                          onMouseLeave={() => setHoveredItem("")}
                        >
                          {factor.title}
                          {(isSelected || allSelected || parentSelected) && (
                            <Icon
                              style={{ marginTop: -20, paddingLeft: 6 }}
                              name="check"
                            />
                          )}
                        </ItemContainer>
                      </Dropdown.Item>
                    );
                  })}
                </FactorSection>
              );
            })}
          </SelectWrapper>
        </Dropdown.Menu>
      </Dropdown>
    </First>
  );
};

export default QuestionDrop;

const First = styled.div`
  display: flex;
  text-transform: uppercase;
  height: 30px;
  padding-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  margin-bottom: 24px;
  margin-left: -20px;
  width: 100%;
  color: #414141;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const FactorSection = styled.div`
  margin-left: 20px;
  padding-right: 10px;
  margin-right: 40px;
`;

const SelectItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;
