import simpleStats from 'simple-statistics';
// Sort the data by quarter and year.
// ! The xAxisSize is currently hardcoded and not configured to be dynamic.
// TODO: Make the xAxisSize dynamic.
export const getDataByQuarter = (surveys, xAxisSize = 6) => {
  // Create an object of quarters.
  const quarterlyData = {
    q1: [],
    q2: [],
    q3: [],
    q4: [],
  };

  // Loop through each survey and add to the quarterlyData object based on date.
  surveys.forEach((survey) => {
    const date = new Date(survey.date);
    const month = date.getMonth();

    const quarter = Math.floor(month / 3) + 1;
    const key = `q${quarter}`;
    quarterlyData[key].push(survey);
  });

  // get current year.
  const currentYear = new Date().getFullYear();

  // get current quarter
  const currentQuarter = Math.floor(new Date().getMonth() / 3) + 1;
  const organizedData = [];
  // Create an object for each quarter based on the xAxisSize.
  for (let i = 0; i < xAxisSize; i++) {
    const quarterBackstep = currentQuarter - xAxisSize;
    const quarterIndex = quarterBackstep * -1 - i;
    if (quarterIndex - 1 < 5 && quarterIndex - 1 > 0) {
      const quarterYears = Math.ceil(quarterIndex / 4);
      const quarterYear = currentYear - quarterYears;
      const quarter = quarterIndex - 1;
      const key = `q${quarter}`;
      organizedData.push({
        key,
        quarterYear,
        data: [],
        quarterKey: `q${quarter}`,
      });
    } else {
      if (quarterIndex > 0) {
        const quarterYear = currentYear;
        const quarter = quarterIndex;
        const key = `q${quarter}`;
        organizedData.push({
          key,
          quarterYear,
          data: [],
          quarterKey: `q${quarter}`,
        });
      } else if (quarterIndex === 0) {
        const quarterYear = currentYear - 1;
        const quarter = 4;
        const key = `q${quarter}`;
        organizedData.push({
          key,
          quarterYear,
          data: [],
          quarterKey: `q${quarter}`,
        });
      } else if (quarterIndex < 0) {
        const quarterYear = currentYear;
        const quarter = quarterIndex * -1 + 1;
        const key = `q${quarter}`;
        organizedData.push({
          key,
          quarterYear,
          data: [],
          quarterKey: `q${quarter}`,
        });
      }
    }
  }

  // Loop through quarterly data and add to the organizedData object if the year matches.
  organizedData.forEach((quarter) => {
    const { quarterKey, quarterYear } = quarter;
    const data = quarterlyData[quarterKey];
    if (!data) return;

    data.forEach((survey) => {
      const date = new Date(survey.date);
      const year = date.getFullYear();
      if (year === quarterYear) {
        quarter.data.push(survey);
      }
    });
  });

  // sort organized data by date, then by quarter.
  organizedData.sort((a, b) => {
    if (a.quarterYear > b.quarterYear) return 1;
    if (a.quarterYear < b.quarterYear) return -1;
    if (a.quarterKey > b.quarterKey) return 1;
    if (a.quarterKey < b.quarterKey) return -1;
    return 0;
  });

  return organizedData;
};

// Get the data for the specified question based on the anchor
const getQuestionDataByAnchor = (quarter, anchor, q, rule) => {
  const responses = [];
  const filteredQuarter = quarter.data.filter((survey) => {
    return survey.responses.find((set) => {
      return set?.response?.employee_outcomes?.responses?.length > 0;
    });
  });

  // Loop through data and find responses that match the anchor.
  const data = filteredQuarter.map((survey) => {
    const responses = survey.responses;
    const responseData = responses.filter((set) =>
      set.response.categories.find((category) => {
        return category.response === anchor;
      })
    );
    return responseData;
  });

  // Loop through data and find the average score for each of the employee_outcomes.
  data.forEach((set) => {
    if (set?.length > rule) {
      set.forEach((response) => {
        if (response.response?.employee_outcomes?.responses) {
          response?.response.employee_outcomes?.responses.forEach((r) => {
            if (Number(r.q) === Number(q)) {
              responses.push(r.response);
            }
          });
        }
      });
    }
  });

  if (responses.length > rule) {
    const average = (
      responses.reduce((a, b) => a + b, 0) / responses.length
    ).toFixed(1);
    return average;
  } else {
    return 0;
  }
};

// Gets the overall average for the specified question.
const getQuestionAverage = (quarter, q, rule) => {
  const responses = [];
  const filteredQuarter = quarter.data.filter((survey) => {
    return survey.responses.find((set) => {
      return set?.response?.employee_outcomes?.responses?.length > 0;
    });
  });

  if (filteredQuarter.length === 0) return 0;

  // Loop through filteredQuarter and add all responses to the responses array.
  filteredQuarter.forEach((survey) => {
    survey.responses.forEach((set) => {
      if (set.response?.employee_outcomes?.responses) {
        set?.response.employee_outcomes?.responses.forEach((r) => {
          if (Number(r.q) === Number(q)) {
            responses.push(r.response);
          }
        });
      }
    });
  });

  // Calculate the average.
  const average = (
    responses.reduce((a, b) => a + b, 0) / responses.length
  ).toFixed(1);
  return average;
};

// Get options for the anchor.
export const getAnchorOptions = (structure, anchor) => {
  const anchorOptions = structure?.categories?.find((set) => set.id === anchor);
  return anchorOptions;
};

// Filter empty datasets for employee_outcomes.
const filterEmptyData = (data) => {
  const filteredData = data.filter((quarter) => {
    return quarter.data.find((survey) => {
      return survey.responses.find((set) => {
        return set?.response?.employee_outcomes?.responses?.length > 0;
      });
    });
  });
  return filteredData;
};

// Gets the overall average for the specified question.
const getOutcomeAverage = (quarter, q, rule) => {
  const responses = [];
  const filteredQuarter = quarter.data.filter((survey) => {
    return survey.responses.find((set) => {
      return set?.response?.employee_outcomes?.responses?.length > 0;
    });
  });

  if (filteredQuarter.length === 0) return 0;

  // Loop through filteredQuarter and add all responses to the responses array.
  filteredQuarter.forEach((survey) => {
    survey.responses.forEach((set) => {
      if (set.response?.employee_outcomes?.responses) {
        set?.response.employee_outcomes?.responses.forEach((r) => {
          if (Number(r.q) === Number(q)) {
            responses.push(r.response);
          }
        });
      }
    });
  });

  // Calculate the average.
  const average = (
    responses.reduce((a, b) => a + b, 0) / responses.length
  ).toFixed(1);
  return average;
};

const getOverallQuestionAverage = (quarter, q, s, rule) => {
  const responses = [];
  const filteredQuarter = quarter.data.filter((survey) => {
    return survey.responses.find((set) => {
      return set?.response?.employee_outcomes?.responses?.length > 0;
    });
  });

  // Loop through data and find responses that match the anchor.
  const data = filteredQuarter.map((survey) => {
    const responses = survey.responses;
    const responseData = responses;
    return responseData;
  });

  // Loop through data and find the average score for each of the employee_outcomes.
  data.forEach((set) => {
    if (set?.length > rule) {
      set.forEach((response) => {
        if (response.response?.employee_outcomes?.responses) {
          response?.response.employee_outcomes?.responses.forEach((r) => {
            if (Number(r.q) === Number(q) && Number(r.s) === Number(s)) {
              responses.push(r.response);
            }
          });
        }
      });
    }
  });

  if (responses.length > rule) {
    const average = (
      responses.reduce((a, b) => a + b, 0) / responses.length
    ).toFixed(1);
    return average;
  } else {
    return 0;
  }
};

// Gets the overall average for the specified question.
const getQuestionAveragePerAnchor = (quarter, anchor, q, s, rule) => {
  const responses = [];
  const filteredQuarter = quarter.data.filter((survey) => {
    return survey.responses.find((set) => {
      return set?.response?.employee_outcomes?.responses?.length > 0;
    });
  });

  // Loop through data and find responses that match the anchor.
  const data = filteredQuarter.map((survey) => {
    const responses = survey.responses;
    const responseData = responses.filter((set) =>
      set.response.categories.find((category) => {
        return category.response === anchor;
      })
    );
    return responseData;
  });

  // Loop through data and find the average score for each of the employee_outcomes.
  data.forEach((set) => {
    if (set?.length > rule) {
      set.forEach((response) => {
        if (response.response?.employee_outcomes?.responses) {
          response?.response.employee_outcomes?.responses.forEach((r) => {
            if (Number(r.q) === Number(q) && Number(r.s) === Number(s)) {
              responses.push(r.response);
            }
          });
        }
      });
    }
  });

  if (responses.length > rule) {
    const average = (
      responses.reduce((a, b) => a + b, 0) / responses.length
    ).toFixed(1);
    return average;
  } else {
    return 0;
  }
};

// Using the organized dataset, calculate the average for the specified question
export const getAnchorAveragesByQuestion = (
  organizedData,
  anchor,
  q,
  structure,
  rule = 2,
  quesObject
) => {
  const primaryAnchor = structure?.categories?.find(
    (cat) => cat.priority === "primary"
  )?.id;

  const filteredData = filterEmptyData(organizedData);
  const calculatedArr = [];

  const anchorData = filteredData.map((quarter) => {
    // Should be renamed to AnchorDataArr, dependent on selected anchor
    const locationDataArr = [];
    // Always gets primary data, independent of anchor
    const primaryData = [];

    if (quarter.data.length === 0) return;
    // Get a list of anchor options.
    const overallAverage = getOutcomeAverage(quarter, q, rule);

    // Default to first anchor if none is specified.
    const anchorId = anchor || structure.categories[0].id;
    const anchorOptions = getAnchorOptions(structure, anchorId);
    const primaryOptions = getAnchorOptions(structure, primaryAnchor);
    let questionAvg;
    if (quesObject?.questions?.length > 0) {
      questionAvg = quesObject.questions.map((question) => {
        const anchorQuestionData = [];
        const outcome = question.q;
        const questionId = question.s;
        const overallQuestionAverage = getOverallQuestionAverage(
          quarter,
          outcome,
          questionId,
          rule
        );
        anchorOptions?.options?.forEach((option) => {
          const questionScore = getQuestionAveragePerAnchor(
            quarter,
            option.id,
            outcome,
            questionId,
            rule
          );

          if (!isNaN(questionScore)) {
            const calculatedObject = {
              option: option.id,
              optionLabel: option.name,
              average: questionScore,
            };
            anchorQuestionData.push(calculatedObject);
          }
        });

        return {
          outcome,
          questionId,
          anchorQuestionData,
          overallQuestionAverage,
        };
      });
    }

    anchorOptions?.options?.forEach((option) => {
      // Retrieves the overall score.
      const calculated = getQuestionDataByAnchor(quarter, option.id, q, rule);

      if (!isNaN(calculated)) {
        const calculatedObject = {
          option: option.id,
          optionLabel: option.name,
          average: calculated,
        };
        calculatedArr.push(calculatedObject);
        locationDataArr.push(calculatedObject);
      }
    });

    primaryOptions?.options?.forEach((option) => {
      const calculated = getQuestionDataByAnchor(quarter, option.id, q, rule);
      if (!isNaN(calculated)) {
        const calculatedObject = {
          option: option.id,
          optionLabel: option.name,
          average: calculated,
        };
        primaryData.push(calculatedObject);
      }
    });

    return {
      anchor: anchor,
      anchorLabel: anchorOptions?.name,
      overallAverage: overallAverage,
      quarter: quarter.quarterKey,
      year: quarter.quarterYear,
      anchorData: calculatedArr,
      locationData: locationDataArr,
      primaryData: primaryData,
      questionData: questionAvg,
    };
  });

  // Remove undefined values.
  return anchorData.filter((set) => set);
};

function calculateAverage(data) {
  // Utility function to get the reversed score
  function getReverseScore(score) {
      return 10 - score + 1; // Assuming the scores range from 1-10
  }

  const aggregated = {};

  data.forEach(item => {
      const key = `${item.factor}-${item.id}`;
      const score = item.reverse ? getReverseScore(item.response) : item.response;

      if (!aggregated[key]) {
          aggregated[key] = {
              sum: 0,
              count: 0
          };
      }

      aggregated[key].sum += score;
      aggregated[key].count++;
  });

  const averages = [];

  for (const [key, values] of Object.entries(aggregated)) {
      const [factor, dimension] = key.split('-').map(Number);
      averages.push({
          factor: factor,
          dimension: dimension,
          average: values.sum / values.count
      });
  }

  return averages;
}


  // Calculate the average.
function calculateOutcomeAverage(arr) {

    if (!arr.length) return 0;  // Handle empty arrays
    const sum = arr.reduce((acc, val) => acc + val.response, 0);
    return sum / arr.length;
}

// Get the response average outcome and the factor averages
const get_outcome_and_factor_averages = (data,q) => {
  if(data?.employee_outcomes?.responses?.find(f=>f.q==q)){
    let outcomes = calculateOutcomeAverage(data?.employee_outcomes?.responses?.filter(f=>f.q==q))
    let factor_scores = calculateAverage(data.questions)
    return {outcomes,factor_scores}
  }

  return {outcomes:null,factor_scores:null}
}

function aggregateData(array) {
  const aggregated = {};
  array.forEach(data => {
      data.factor_scores.forEach(score => {
          const key = `${score.factor}-${score.dimension}`;

          if (!aggregated[key]) {
              aggregated[key] = {
                  factor: score.factor,
                  dimension: score.dimension,
                  average: [],
                  outcome: []
              };
          }

          aggregated[key].average.push(score.average);
          aggregated[key].outcome.push(data.outcomes);
      });
  });

  // Extracting aggregated values to form final result array
  const result = [];
  for (let key in aggregated) {
      result.push(aggregated[key]);
  }

  return result;
}


function calculateMean(arr) {
  return arr.reduce((acc, val) => acc + val, 0) / arr.length;
}

function pearsonCorrelation(arrX, arrY) {
  const meanX = calculateMean(arrX);
  const meanY = calculateMean(arrY);

  const numerator = arrX.reduce((acc, val, idx) => acc + (val - meanX) * (arrY[idx] - meanY), 0);
  const denominator = Math.sqrt(
    arrX.reduce((acc, val) => acc + Math.pow(val - meanX, 2), 0) *
    arrY.reduce((acc, val) => acc + Math.pow(val - meanY, 2), 0)
  );

  if (denominator === 0) return 0; // prevent division by zero

  return numerator / denominator;
}

function calculatePValueForCorrelation(r, n) {
  if (r === 1 || r === -1) {
    return 0; // perfect correlation, p-value is 0
  }
  
  const t = r * Math.sqrt((n - 2) / (1 - r * r));
  
  // Calculate two-tailed p-value
  return 2 * (1 - simpleStats.tTestTwoSample(n - 2, Math.abs(t)));
}

function processCorrelation(data) {
  const map = {};

  data.forEach(item => {
    const key = `${item.factor}-${item.dimension}`;
    if (!map[key]) {
      map[key] = { average: [], outcome: [] };
    }
    map[key].average.push(...item.average);
    map[key].outcome.push(...item.outcome);
  });

  const results = [];
  for (const [key, value] of Object.entries(map)) {
    const [factor, dimension] = key.split('-').map(Number);
    const correlation = pearsonCorrelation(value.average, value.outcome);
   
    results.push({ factor, dimension, correlation });
  }

  return results;
}



export const calculate_correlations = (data,qId) =>{
  let results_arr = []
  data.forEach((set) => {
    let calculated = set.responses.map((response) => {
      return get_outcome_and_factor_averages(response.response,qId)
    })
    results_arr = [...results_arr,...aggregateData(calculated.filter(f=>f.outcomes))]
  })

  return(processCorrelation(results_arr))

}