import styled from "styled-components";
import React, { useEffect, useState } from "react";
import BasicDropdown from "./PlanComponent/Dropdown";
import { SURVEY_INFO } from "constants/surveys";
import {Dropdown} from 'semantic-ui-react'

const organize_questions = (q) => {
  if (q.survey_type === SURVEY_INFO.edi.question_type) {
    return q.questions.factors
      .map((i, id) => {
        return {
          key: i.title,
          text: i.title,
          value: { title: i.title, f: id },
        };
      })
      .flat();
  }

  return q.questions.dimensions
    .map((i, id) => {
      return i.factors.map((x, idx) => {
        return {
          key: x.title,
          text: x.title,
          value: { title: x.title, d: id, f: idx },
        };
      });
    })
    .flat();
};

const getFactorIndex = (factor, options) => {
  if (options && factor) {
    const found = options.findIndex(
      (option) => option.key.toLowerCase() === factor.title.toLowerCase()
    );
    return found;
  }

  return 0;
};

const organize_questions2 = (q, s, o, removeAllQuestions) => {
  let questions = !removeAllQuestions
    ? [{ key: -1, text: "All questions", value: -1 }]
    : [];
  if (q.survey_type === SURVEY_INFO.edi.question_type) {
    questions.push(
      ...q.questions.factors[s.f].questions.map((qx, idx) => {
        return {
          key: qx.q,
          text: qx.q,
          value: idx,
          id: qx?.id,
        };
      })
    );
  } else {
    questions.push(
      ...q.questions.dimensions[s.d].factors[s.f].questions.map((qx, idx) => {
        return {
          key: qx.q,
          text: qx.q,
          value: idx,
          id: qx?.id,
        };
      })
    );
  }

  return questions;
};

const Metric = ({
  questions,
  selectedFactor,
  setSelected2,
  setSelected,
  selected2,
  editMode,
  disableDropdown,
  onSelectQuestion,
  removeAllQuestions,
  altPrompt,
}) => {
  const [open, setOpen] = useState(false);
  const [options1, setOptions] = useState(organize_questions(questions));
  const [options2, setOptions2] = useState([]);

  useEffect(() => {
    if (selectedFactor) {
      setOptions2(
        organize_questions2(
          questions,
          selectedFactor,
          options1,
          removeAllQuestions
        )
      );
    }
  }, [selectedFactor, options1]);

  const description = altPrompt
    ? "Equity, Inclusion, and Accessibility are 3 factors that influence culture in sport. Please identify the culture dimension where Equity, Inclusion and Accessibility requires additional attention."
    : "Please select the focus for this action plan.";

  const descriptionText = editMode
    ? "Change the metrics for this action plan."
    : description;

  return (
    <Container>
      <Top onClick={() => setOpen(!open)}>
        {!editMode && <Title>Metrics</Title>}
        <Description>{descriptionText}</Description>
      </Top>

      <SelectionContainer>
        <Label>DEI Dimension</Label>
        <Dropdown
          options={options1}
          fluid
          selection
          placeholder="Select a dimension"
          value={selectedFactor}
          style={{ marginBottom: 20 }}
          onChange={(t, { value }) => {
            !disableDropdown && setSelected(value);
          }}
        />
        <Label>DEI Question</Label>
        <Dropdown
          options={options2}
          fluid
          selection
          placeholder="Select a template"
          value={selected2?.[0]}
          onChange={(t, { value }) => {
            setSelected2(value);
            if (onSelectQuestion && removeAllQuestions) {
              onSelectQuestion({
                factor: selectedFactor?.f,
                dimension: selectedFactor?.d,
                question: value,
                value: value,
              });
            }
          }}
        />
        {/* <BasicDropdown
          label="Question"
          fullWidth={true}
          selectedOption={
            (selected2?.length > 0 &&
              options2?.find((op) => op.value === selected2[0])?.text) ||
            "Select an option"
          }
          handleOptionChange={(t) => {
            setSelected2(t);
          }}
          options={options2}
        /> */}
      </SelectionContainer>
    </Container>
  );
};

export default Metric;

const Container = styled.div`
  width: 100%;
  border-radius: 3px;
  padding-top: 30px;
  font-family: "Raleway";
`;

const Top = styled.div`
  display: flex;
  flex-direction:column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color:#2A3039;
  line-height: 21px;
  margin-right: 15px;
  margin-bottom:10px;
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 25px;
  color: #2A3039;
`;


const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`;

const SelectionContainer = styled.div`
  display: flex;
  margin-top: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7e7e7e;
  width: 100%;
  flex-direction: column;
`;

const Selected = styled.div``;

const templateOptions = [
  {
    key: "Strategic Gap Analysis",
    text: "Strategic Gap Analysis",
    value: "Strategic Gap Analysis",
  },
];


const Label = styled.div`
  color: #666D79;
  font-size:12px;
  font-weight:400;
`