import { erf } from "mathjs";

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const retrieveLinearCalc = (data, factor, questions, dimension) => {
  let vQuestions = [];

  data.questions.forEach((fd) => {
    if (fd.factor === factor && fd.id === dimension) {
      vQuestions.push(fd);
    }
  });

  let vValues = [];
  vQuestions.forEach((q) => {
    let val;
    if (q.reverse) {
      val = (q.reverse && reverse[Math.floor(q.response)]) || q.response;
    } else {
      val = check_reverse_score(q, questions);
    }
    vValues.push(val);
  });

  let sum = 0;
  vValues.forEach((v) => {
    if (v) {
      sum += v;
    }
  });
  const avg = sum / vValues.length;

  return avg;
};

const retrieveLinearCalcAggregate = (data, factorsObj, questions) => {
  let vQuestions = [];

  factorsObj?.id?.factors?.forEach((f) => {
    data.questions.forEach((fd) => {
      if (fd.factor === f.id - 1 && fd.id === factorsObj?.id?.id - 1) {
        vQuestions.push(fd);
      }
    });
  });

  let vValues = [];
  vQuestions.forEach((q) => {
    let val;
    if (q.reverse) {
      val = (q.reverse && reverse[Math.floor(q.response)]) || q.response;
    } else {
      val = check_reverse_score(q, questions);
    }
    vValues.push(val);
  });

  let sum = 0;
  vValues.forEach((v) => {
    if (v) {
      sum += v;
    }
  });
  const avg = sum / vValues.length;

  return avg;
};

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions?.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? reverse[Math.floor(resp.response)] : resp.response;
  return response;
};

const getGroupedData = (group, data) => {
  const groupingOptions = group.text.options;
  return groupingOptions.map((option) => {
    const filteredData = data.filter((item) => {
      return item.categories.find((catResp) => {
        return catResp.response === option.id;
      });
    });

    return { id: option.id, groupName: option.name, data: filteredData };
  });
};

const getGroupAverage = (groupedData, questionStructure, x, y) => {
  // Outer map will be groupedData [x, y, groupName]
  return groupedData.map((group) => {
    // Inner map will be array of x
    const xArr = group.data
      .map((item) => get_item(item, x, questionStructure))
      .filter((i) => !!i);
    const yArr = group.data
      .map((item) => get_item(item, y, questionStructure))
      .filter((i) => !!i);
    // Next we get the averages for each group.
    const xAvg =
      Math.round((xArr.reduce((a, b) => a + b, 0) / xArr.length) * 100) / 100;
    const yAvg =
      Math.round((yArr.reduce((a, b) => a + b, 0) / yArr.length) * 100) / 100;

    // Finally we return our grouped data [x, y, groupName]
    return [xAvg, yAvg, group.groupName];
  });
};

export const fetch_data = (data, x, y, questionStructure, grouping) => {
  if (grouping) {
    const groupedData = getGroupedData(grouping, data);
    return getGroupAverage(groupedData, questionStructure, x, y).filter(
      (arr) => {
        return !!arr[0] && !!arr[1];
      }
    );
  } else {
    return data
      .map((item) => {
        let _x = get_item(item, x, questionStructure);
        let _y = get_item(item, y, questionStructure);
        return [_x, _y];
      })
      .filter((arr) => {
        return !!arr[0] && !!arr[1];
      });
  }
};

const get_item = (data, value, questionStructure) => {
  let type = value.type;

  if (type === "personality") {
    let id = value.id;
    return calculate_personality(data.survey_personality, id);
  }
  if (type === "records") {
    let recordVal;
    const numericalRegex = /[$|€|£]?(\d*\s?[\,?\d{3,}]*\.?\d+)/;
    let hasRecords = false;
    data.employee_records.forEach((item) => {
      if (item) {
        hasRecords = true;
      }
    });
    if (hasRecords) {
      console.log(value.text);
      data.employee_records.forEach((recordObj) => {
        if(recordObj?.[value.text]){
          recordVal = recordObj?.[value.text];
        }
        else if (recordObj?.[value.text.toLowerCase()]) {
          recordVal = recordObj?.[value.text.toLowerCase()];
        }

      });


      if (recordVal) {
        let cleanedVal = recordVal.toString().match(numericalRegex);
        if (cleanedVal.input.match(/€/)) {
          cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
          cleanedVal = Number(cleanedVal) * 1.35;
        } else {
          cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
          cleanedVal = cleanedVal.replace(/,/g, "");
        }
        return Number(cleanedVal);
      }
    }
  }
  if (type === "culture") {
    if (value?.factor === null) {
      return retrieveLinearCalcAggregate(data, value, questionStructure);
    } else {
      return retrieveLinearCalc(
        data,
        value.factor - 1,
        questionStructure,
        value.id?.id - 1
      );
    }
  }
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};
