
// This file contains the calculations for the Tag report


// This function returns the most recent responses for each primary factor
export const get_most_recent = (responses) => {
    let tracker = {};
    return {
      date: null,
      responses: responses
        .filter((i) => {
          if (!(i.name in tracker)) {
            tracker[i.name] = i.date;
          }
  
          return i.date === tracker[i.name];
        })
        .map((i) => i.responses)
        .flat()
        .map((i) => i.response)
        .filter((f) => !Array.isArray(f)),
    };
  };

  export const get_last_responses = (responses) => {
    let tracker = {};
  
    return responses
      .filter((i) => {
        if (!(i.name in tracker)) {
          tracker[i.name] = [i.date];
        } else if (tracker[i.name].length === 1) {
          tracker[i.name].push(i.date);
        }
  
        if (tracker[i.name].length === 2) {
          return i.date == tracker[i.name][1];
        }
        return false;
      })
      .map((i) => i.responses)
      .flat()
      .map((i) => i.response)
      .filter((f) => !Array.isArray(f));
  };



  export function filter_data(data, filters) {
    function responseMatchesFilter(response) {
      // Extract all the response ids
      const responseIds = response.categories.map((cat) => cat.response);

      // Group filters by parent
      const groupedFilters = filters.reduce((acc, filter) => {
        if (!acc[filter.parent]) acc[filter.parent] = [];
        acc[filter.parent].push(filter.id);
        return acc;
      }, {});

      // Check for every parent
      for (let parent in groupedFilters) {
        const requiredIds = groupedFilters[parent];

        // If none of the required ids for a given parent are present in the response, filter it out
        if (!requiredIds.some((id) => responseIds.includes(id))) {
          return false;
        }
      }
      return true;
    }

    return data.filter(responseMatchesFilter);
  }

  const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

export const getOverallSpread = (data) => {
  let high;
  let low;

  data.forEach((item) => {
    item?.questions?.forEach((question) => {
      let val;
      if (question.response) {
        if (question.reverse) {
          val = rev_order[Number(question.response)];
        } else {
          val = Number(question.response);
        }
      }

      if (!!val) {
        if (!high) {
          high = val;
        }
        if (!low) {
          low = val;
        } else {
          if (val > high) {
            high = val;
          }

          if (val < low) {
            low = val;
          }
        }
      }
    });
  });

  return high - low;
};

  export const get_overall_average = (data) => {
    let totalSum = 0;
    let totalCount = 0;

    data.forEach((item) => {
      item.questions.forEach((question) => {
        if (typeof question.response === "number") {
          if (question.reverse) {
            totalSum += rev_order[question.response];
          } else {
            totalSum += question.response;
          }
          totalCount++;
        }
      });
    });

    return totalCount ? totalSum / totalCount : 0; // Avoid division by zero
  };

  const sum_array = (arr) => {
    if (!arr) {
      return 0;
    }

    let sum = 0;
    arr.forEach((item) => {
      sum += item;
    });
    return sum;
  };

  export const get_hr_flags = (data) => {
    let flags = 0;
    let feedback_quality = 0;
    let totalCount = 0;

    data.forEach((item) => {
      item?.ml_feedback?.feedback?.forEach((question) => {
        flags += sum_array(question?.conversation_analysis?.hr_flag);

        question?.conversation_analysis?.quality.forEach((quality) => {
          feedback_quality += quality;
          totalCount++;
        });
      });
    });

    return { flags, feedback_quality: feedback_quality / totalCount };
  };

  const get_factor_average = (data, factor) => {
    let totalSum = 0;
    let totalCount = 0;
    data.forEach((item) => {
      item.questions.forEach((question) => {
        if (question.factor === factor) {
          totalSum += question.response;
          totalCount++;
        }
      });
    });

    return totalCount ? totalSum / totalCount : 0; // Avoid division by zero
  };

const get_item_scores = (data, index,factor) => {
    let scores = []
    factor.questions.forEach((item,question)=>{
        let totalSum = 0;
        let totalCount = 0;
        data.forEach(response=>{
            response.questions.forEach((q,i)=>{
                if(q.factor === index  && q.q === question){
                    totalSum += q.response;
                    totalCount++;
                }
            })
        })
        scores.push({question:item.q,score:totalCount ? totalSum / totalCount : 0})
    })
    return scores
}

const get_pillar_standard = (standards,index) => {
   return standards?.response.pillars.find(f=>f.factor==index)
}

export const get_scores_per_pillar = (data,survey,org_data,last_data,standards) =>{
    let scores = []
    console.log(survey)
    survey.questions.factors.map((factor,dimension)=>{
           scores.push({name:factor.title,
                        score:get_factor_average(data,dimension),
                        item_scores:get_item_scores(data,dimension,factor),
                        dimension,
                        factor:dimension,
                        last_score:get_factor_average(last_data,dimension),
                        pillar_standard:get_pillar_standard(standards,dimension),
                        org_score:get_factor_average(org_data,dimension)})

    })
    return scores
}

export const getStructuredFeedback = (data) => {
    let feedback = []
    data?.map((item)=>{
        item.feedback.map((f)=>{
           feedback.push(f)
        })
    })

    return feedback
}