import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Table, Label, Icon, Pagination,Dropdown } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const colors = {
	"Technical Leaders": "blue",
	"Sport Science": "green",
	Athletes: "red",
	Coach: "yellow",
};

const createOptions = (structure,is_french) =>{

	let options = []
	options.push({
    text: is_french ? "Tous les Domaines DEI" : "All DEI Domains",
    value: { d: -1, f: -1 },
  });

	structure.dimensions[0].factors.map((item,i)=>{
		options.push({text:is_french?item.fr:item.title,value:{d:0,f:i}})
	})

	if(structure.dimensions.length>1){
		structure.dimensions[1].factors.map((item,i)=>{
		options.push({text:is_french?item.fr:item.title,value:{d:1,f:i}})
	})
	}
	

	return options
}

const RawData = ({ data, labels, structure,is_french,language_hook }) => {
	const raw = useSelector((state) => state.audit?.raw_data, shallowEqual);
	const get_organizations = useSelector((state) => state.organizations, shallowEqual);

	const [options,setOptions] = useState(createOptions(structure,is_french))

	// const get_survey_questions = useSelector(
	// 	(state) => state.surveyquestions.survey_questions,
	// 	shallowEqual
	// );
	// const otp_check = get_organizations.organization.services_enabled.find(f=>f.name=='OTP').enabled
	// const survey_type = get_organizations.organization.services_enabled.find(f=>f.name==(otp_check?'OTP':'Audit')).question_sort_order
	// console.log(structure,get_survey_questions[0])
	// const survey_questions = survey_type===0? get_survey_questions[0]:get_survey_questions.find(f=>f.sort_order==survey_type)


	const questions = {
		title: structure.title,
		dimensions: structure.dimensions,
		type: structure.type,
		type_name: structure.type_name,
	};


	const sort = (data, direction) => {
		if (direction > 0) {
			return data.sort((a, b) => {
				return a.average - b.average;
			});
		}
		return data.sort((a, b) => {
			return b.average - a.average;
		});
	};

	const sortDev = (data, direction) => {
		if (direction > 0) {
			return data.sort((a, b) => {
				return a.stdev - b.stdev;
			});
		}
		return data.sort((a, b) => {
			return b.stdev - a.stdev;
		});
	};

	// const calculate_norm = (data) =>{
	// 	return data.responses.map((item)=>{
	// 		let factor ={dimension:item.dimension,factor:item.section,x:0,y:0}
	// 		let norm = calculate_norms(raw,Number(item.role),item.average,factor,Number(item.question),)
	// 		let new_data = item
	// 		new_data['norm'] = norm
	// 		return new_data
	// 	})
	// }

	const [normData, setNorm] = useState([]);
	const [dir, setDire] = useState(-1);
	const [sorted, setSorted] = useState(sort(data.responses, dir));
	const [page, setPage] = useState(1);
	const [col, setCol] = useState(0);
	const [selected,setSelected] = useState(options[0].value)

	const handlePaginationChange = (e, { activePage }) => setPage(activePage);

	const update = (newdir, colval) => {
		setCol(colval);
		setDire(newdir);
		if (colval == 0) {
			setSorted(sort(sorted, newdir));
		} else {
			setSorted(sortDev(sorted, newdir));
		}
	};

	useEffect(() => {

		if(selected.d!=-1){
			setSorted(sort(data.responses.filter(f=>f.dimension==selected.d && Number(f.section)==selected.f), dir))
		}else{
			setSorted(sort(data.responses, dir));
		}
		
		setCol(0);
	}, [data]);

	useEffect(()=>{
		if(selected.d!=-1){
			setSorted(sort(data.responses.filter(f=>f.dimension==selected.d && Number(f.section)==selected.f), dir))
		}else{
			setSorted(sort(data.responses, dir))
		}
	},[selected])

	return (
		<Container>
			
			<Table color={"violet"} basic>

					<Table.Header style={{fontFamily:'Poppins',fontWeight:500,fontSize:16,color:'#414141'}}>
						<Table.Row>
							<Table.HeaderCell width={4}>Question</Table.HeaderCell>
							<Table.HeaderCell width={2}>{language_hook('Domain')}</Table.HeaderCell>
							<Table.HeaderCell width={3}>
								 <Dropdown
								      inline
								      options={options}
								      value={selected}
								      onChange={(e,d)=>setSelected(d.value)}
								    />

							</Table.HeaderCell>
							<Table.HeaderCell width={2}>{language_hook('Anchor')}</Table.HeaderCell>
							<Table.HeaderCell
								width={1}
								style={{ cursor: "pointer" }}
								onClick={() => update(dir * -1, 0)}
							>
								Score{col == 0 ? (dir < 0 ? " ↑" : " ↓") : ""}
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

				<Table.Body style={{fontFamily:'Poppins',fontWeight:400,fontSize:10,color:'#7E7E7E'}}>
					{sorted.slice((page - 1) * 10, page * 10).map((item, i) => {
						let question = Number(item.question)
						let section = Number(item.section)
						console.log(item,questions)
						return (
							<Table.Row>
								<Table.Cell>
									{	is_french?
											questions.dimensions[item.dimension]
											.factors[section].questions[
											question
										].fr:
										questions.dimensions[item.dimension]
											.factors[section].questions[
											question
										].q
									}
								</Table.Cell>
								<Table.Cell>
									<StyledLabel
										color={
											item.dimension == 0
												? "rgba(181, 181, 181,0.7)"
												: "rgba(201, 124, 12,0.4)"
										}
										horizontal
									>
										{is_french?questions.dimensions[item.dimension].fr:questions.dimensions[item.dimension].title}
									</StyledLabel>
								</Table.Cell>
								<Table.Cell>
									{
											is_french?
											questions.dimensions[item.dimension]
											.factors[section].fr:

										questions.dimensions[item.dimension]
											.factors[section].title
									}
								</Table.Cell>
								<Table.Cell>
									<StyledLabel
										color={item.label.color}
										horizontal
									>
										{is_french?item.label.fr:item.label.name}
									</StyledLabel>
								</Table.Cell>
								<Table.Cell>
									<Score>{Math.floor(item.average * 10) / 10}</Score>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
			<div
				style={{
					padding: 10,
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Pagination
					totalPages={Math.ceil(sorted.length / 10)}
					onPageChange={handlePaginationChange}
					activePage={page}
					size="mini"
				/>
			</div>
		</Container>
	);
};

export default RawData;

const Container = styled.div`
	padding-left:58px;
	padding-top:19px;
`;

const HeaderStyle = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */

color: #414141;
`

export const StyledLabel = styled(Label)`
	background-color: ${(props) => props.color || "green"} !important;
`;

const Score = styled.div`
	font-family: Poppins;
	font-size:16px;
	text-align:center;
`