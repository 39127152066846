import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon, Dropdown, Modal, Header } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Assignee from "./Assignee";
import Tabs from "./Tabs";
import Update from "./Update";
import planningTemplates from "./templates/planningTemplates.json";
import useGetOrgData from "hooks/useGetOrgData";
import useGetActionPlan from "hooks/useGetActionPlan";
import Metric from "./Metric";
import DatePick from "./DatePicker";
import { useLocation } from "react-router";
import { SURVEY_INFO } from "constants/surveys";
import { TrashIcon, EditIcon, CalendarIcon, TeamIcon } from "./Icons";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_QUESTIONS,
  SG_UPDATE_ACTION_PLAN,
  SG_DELETE_ACTION_PLAN,
} from "constants/actions";

var dateFormat = require("dateformat");

const Edit = ({ open, close, data, updatePlans, plans, addToastStatus }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const options = ["Details", "Activity", "PDF Document"];
  const [tab, setTab] = useState(options[0]);
  const [editObject, setEditObject] = useState({ ...data });
  const { employeesListSafe } = useGetOrgData();
  const [employees, setEmployees] = useState();
  const [startDate, setStartDate] = useState(new Date(data?.startDate));
  const [endDate, setEndDate] = useState(new Date(data?.endDate));
  const [addAssignee, setAddAssignee] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const planIndex = plans?.findIndex((plan) => data.id === plan.id);
  const { get_survey_structure, get_survey_questions } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );
  const [openStatusChange, setOpenStatusChange] = useState(false);
  const { planActivity } = useGetActionPlan({ planId: data.id });
  const [defaultQuestions, setDefaultQuestions] = useState();

  const { isOTP } = useGetOrgData({
    orgCategories: true,
  });
  const statusKeys = Object.keys(planStatus);
  const getPlanStatus = (id) => {
    const keys = Object.keys(planStatus);

    let status = null;
    keys.forEach((key) => {
      if (planStatus[key].id === id) {
        status = planStatus[key];
      }
    });

    return status;
  };

  const questionSections =
    planningTemplates[data.plan].planTemplate.planSections;

  const surveyInfo = location.pathname.includes("edi/action")
    ? {
        type: SURVEY_INFO.edi.question_type,
        auditType: SURVEY_INFO.edi.type,
      }
    : {
        type: SURVEY_INFO.audit.question_type,
        auditType: SURVEY_INFO.audit.type,
      };
  //Pull survey questions and categories from redux
  useEffect(() => {
    dispatch({ type: SG_GET_SURVEY_STRUCTURES });
  }, [dispatch]);

  const handleDatesSelected = (date, startDate = false) => {
    const _data = Object.assign({}, data);

    if (startDate) {
      setStartDate(date);
      _data.startDate = date;
    } else {
      setEndDate(date);
      _data.endDate = date;
    }

    setEditObject(_data);
  };

  useEffect(() => {
    if (employeesListSafe && !employees) {
      const employeeOptions = employeesListSafe.map((employee) => {
        if (employee.id) {
          return {
            key: employee.id,
            text: `${employee.first_name} ${employee.last_name}`,
            value: {
              id: employee.id,
              name: `${employee.first_name} ${employee.last_name}`,
              member: `${employee.first_name} ${employee.last_name}`,
            },
          };
        }
      });
      setEmployees(employeeOptions);
    }
  }, [employees, employeesListSafe]);

  const updateMain = (obj) => {
    const _data = [...plans];
    _data[planIndex] = obj;

    updatePlans(_data);
  };

  const setMember = (d) => {
    const _data = Object.assign({}, data);
    _data.member = d || [];
    setEditObject(_data);
    if (!editMode) {
      updateAssignees(d);
      updateMain(_data);
    }
  };

  const updateAssignees = (assignees) => {
    const membersList = assignees?.map((p) => {
      return p.id;
    });

    const updateData = {
      assignees: membersList,
    };

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: editObject.id },
    });
  };

  const setStatus = (d) => {
    const _data = Object.assign({}, data);
    _data.status = d;
    setEditObject(_data);
    if (!editMode) {
      updateStatus(d);
      updateMain(_data);
      setOpenStatusChange(false);
    }
  };

  const updateStatus = (status) => {
    const updateData = {
      status: status,
    };

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: editObject.id },
    });
  };

  const handleUpdate = (field, value) => {
    // field.id, e.target.value
    const _data = { ...editObject };
    _data.planData[field] = value;
    if (editMode) {
      setEditObject(_data);
    }
  };

  const handleEditCancel = () => {
    setEditObject(JSON.parse(JSON.stringify(data)));
    setEditMode(false);
    setAddAssignee(false);
  };

  const handleSubmit = () => {
    const membersList = editObject.member.map((p) => {
      return p.id;
    });

    const updateData = {
      metric: editObject.metric.title.toLowerCase(),
      action_plan: editObject.plan,
      plan: [
        {
          planId: editObject.plan,
          planData: editObject.planData,
        },
      ],
      status: editObject.status,
      primary: editObject.primary,
      start_date: editObject.startDate,
      end_date: editObject.endDate,
    };

    if (editObject.question?.[0]) {
      updateData.question =
        editObject.question[0] > -1 ? [editObject.question[0]] : [];
    } else {
      updateData.question = [data.question[0]];
    }

    if (membersList.length > 0) {
      updateData.assignees = membersList;
    }

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: editObject.id },
    });
    updateMain(editObject);
    setEditMode(false);
    addToastStatus("Updated Action Plan", { appearance: "success" });
    close();
  };

  const setFactor = (d) => {
    const _data = { ...editObject };
    _data.metric = d;
    setEditObject(_data);
  };

  const setQ = (q) => {
    const _data = Object.assign({}, editObject);

    if (q) {
      _data.question = q === -1 ? [] : [q?.value];
    } else {
      _data.question = data.question[0] === -1 ? [] : [data.question[0]];
    }
    setEditObject(_data);
  };

  const getQ = () => {
    let dimension = editObject.metric?.d;
    let factor = editObject.metric.f;
    let question = editObject.question[0] || data.question[0];
    if (editObject?.sort_order) {
      const surveyQues = get_survey_questions.filter(
        (sq) => sq.sort_order === editObject.sort_order
      )?.[0];

      if (isNaN(dimension)) {
        return (
          surveyQues.questions.factors[factor].questions[question]?.q ||
          "All questions"
        );
      }
      return (
        surveyQues.questions.dimensions[dimension].factors[factor].questions[
          question
        ]?.q || "All questions"
      );
    } else if (question || question === 0) {
      if (isNaN(dimension)) {
        return (
          get_survey_questions[0].questions.factors[factor].questions[question]
            ?.q || "All questions"
        );
      }

      return (
        get_survey_questions[0].questions.dimensions[dimension].factors[factor]
          .questions[question]?.q || "All questions"
      );
    }

    return "All Questions";
  };

  const handleConfirmDelete = () => {
    dispatch({
      type: SG_DELETE_ACTION_PLAN,
      payload: data.id,
    });
    setShowConfirmationModal(false);

    const _data = [...plans];
    updatePlans(_data.filter((plan) => plan.id !== data.id));
    addToastStatus("Deleted Action Plan", { appearance: "success" });
    close();
  };

  const handleArchivePlan = () => {
    const _data = Object.assign({}, data);
    _data.status = 11;

    const updateData = {
      id: data.id,
      status: 11,
    };

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: data.id },
    });
    updateMain(_data);
    addToastStatus("Archived Action Plan", { appearance: "success" });

    setShowConfirmationModal(false);
    close();
  };

  const getSportTitle = () => {
    return get_survey_structure?.[0].categories.find(
      (f) => f.priority === "primary"
    ).name;
  };

  const getSport = () => {
    let sportName = get_survey_structure?.[0].categories
      .find((f) => f.priority === "primary")
      .options.find((f) => f.id === editObject.primary)?.name;

    if (!sportName && isOTP) {
      const sportPrimary = get_survey_structure?.[0].categories
        .find((f) => f.priority === "primary")
        .options.find((o) => {
          return o?.subfactors.find((sf) => sf.id === editObject.primary);
        });
      if (sportPrimary) {
        sportName = sportPrimary.subfactors.find(
          (sf) => sf.id === editObject.primary
        ).name;
      }
    }

    return sportName;
  };

  const handleDelete = () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    if (editObject && get_survey_questions) {
      const surveyQues = get_survey_questions.filter(
        (sq) => sq.sort_order === editObject.sort_order
      )?.[0];

      if (surveyQues) {
        setDefaultQuestions(surveyQues);
      }
    }
  }, [editObject, get_survey_questions]);

  if (get_survey_structure.length === 0) {
    return <></>;
  }
  return (
    <>
      <Modal
        open={open}
        onClose={() => close()}
        closeIcon
        style={{
          minHeight: "70%",
          position: "absolute",
          width: 870,
          padding: 20,
          fontFamily: "Raleway",
        }}
      >
        <Modal.Content style={{ overflow: openStatusChange && "hidden" }}>
          <TopContainer2>
            <RowApart>
              <Title>{data ? editObject.metric.title : ""} Plan</Title>
              {(!editMode && (
                <EditControlWrapper>
                  <ActionButtonWrapper onClick={() => setEditMode(true)}>
                    {EditIcon()}
                  </ActionButtonWrapper>
                  <ActionButtonWrapper onClick={() => handleDelete()}>
                    {TrashIcon()}
                  </ActionButtonWrapper>
                </EditControlWrapper>
              )) || (
                <EditControlWrapper>
                  <Icon
                    name="check"
                    style={{
                      color: "#9b9b9b",
                      cursor: "pointer",
                      fontSize: 20,
                    }}
                    onClick={() => handleSubmit()}
                  />
                  <Icon
                    name="cancel"
                    style={{
                      color: "#9b9b9b",
                      cursor: "pointer",
                      fontSize: 20,
                    }}
                    onClick={() => handleEditCancel()}
                  />
                </EditControlWrapper>
              )}
            </RowApart>

            <Row>
              <T2>Assigned</T2>
              {(!addAssignee && (
                <Assignee
                  setAddAssignee={(val) => setAddAssignee(val)}
                  editMode={true}
                  assignees={editObject.member}
                  stacked={false}
                />
              )) || (
                <Dropdown
                  placeholder="Tag team members"
                  onBlur={() => setAddAssignee(false)}
                  fluid
                  selection
                  search
                  multiple
                  options={employees}
                  value={editObject.member}
                  onChange={(e, t) => setMember(t.value)}
                  style={{ marginRight: 20 }}
                />
              )}
            </Row>

            <Row>
              <T2>Timeline</T2>
              <DateWrapper>
                <Dated>
                  {dateFormat(editObject.startDate, "mmmm d, yyyy")} -{" "}
                </Dated>
                &nbsp;
                <Dated>{dateFormat(editObject.endDate, "mmmm d, yyyy")}</Dated>
                <CalIconWrapper>{CalendarIcon()}</CalIconWrapper>
              </DateWrapper>
            </Row>

            <Row>
              <T2>Status</T2>
              <StatusContainer>
                <Status
                  color={getPlanStatus(editObject.status).color}
                  onClick={() => setOpenStatusChange(!openStatusChange)}
                >
                  <StatusText>
                    {getPlanStatus(editObject.status).statusText}
                  </StatusText>
                </Status>
                {openStatusChange && (
                  <StatusDropdown>
                    {statusKeys.map((key, i) => {
                      const statusData = planStatus[key];
                      if (statusData.id === data.status) {
                        return null;
                      }

                      return (
                        <StatusSelector
                          isLast={statusKeys.length - 1 === i}
                          color={statusData.color}
                          onClick={() => setStatus(statusData.id)}
                        >
                          {statusData.statusText}
                        </StatusSelector>
                      );
                    })}
                  </StatusDropdown>
                )}
              </StatusContainer>
            </Row>

            <Row>
              <T2>{data ? getSportTitle() + "" : ""}</T2>
              <IconWrapper>{TeamIcon("#9E9FA1")}</IconWrapper>
              <SportTextWrapper>{data ? getSport() : ""}</SportTextWrapper>
            </Row>
          </TopContainer2>
          <TabsWrapper>
            <Tabs
              options={options}
              selected={tab}
              onSelect={(s) => setTab(s)}
            />
          </TabsWrapper>
          <div style={{ paddingTop: "10px", paddingRight: 30 }}>
            {tab === "Details" ? (
              <>
                {!editMode && (
                  <TopWrapper>
                    {/* <Title>{data ? editObject.metric.title : ""} Plan</Title>*/}
                    <Top>DEI Pillar</Top>
                    <R>{data ? getQ() : ""}</R>
                  </TopWrapper>
                )}
                {editMode && (
                  <>
                    <Metric
                      editMode={editMode}
                      questions={defaultQuestions || get_survey_questions[0]}
                      selectedFactor={editObject.metric}
                      selected2={editObject.question}
                      setSelected={(d) => setFactor(d)}
                      setSelected2={(d) => setQ(d)}
                    />
                    <DatePickWrapper>
                      <Label>Update the timeline</Label>
                      <DatePick
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={(date, isStartDate) =>
                          handleDatesSelected(date, isStartDate)
                        }
                      />
                    </DatePickWrapper>
                  </>
                )}
                {questionSections.map((section, s) => {
                  return (
                    <>
                      <TitleWrapper>
                        {s === 0 && <TitleTop>Plan Details</TitleTop>}
                        <T1>({section.sectionTitle})</T1>
                      </TitleWrapper>
                      {section.questions.map((question, i) => {
                        return (
                          <Area>
                            <Q>{question.question}</Q>
                            {question.inputFields.map((field, f) => {
                              // Show or hide title if it exists.
                              const showTitle = field.title.length > 0 && (
                                <T5>{field.title}</T5>
                              );

                              // Function to handle conditional wrapping of textarea
                              const textWrapper = (children) => {
                                if (showTitle) {
                                  return <TextArea>{children}</TextArea>;
                                }
                                return <>{children}</>;
                              };

                              const children = !editMode ? (
                                <TextInputWrapper>
                                  {showTitle}
                                  <R>
                                    {data ? editObject.planData[field.id] : ""}
                                  </R>
                                </TextInputWrapper>
                              ) : (
                                <TextInputWrapper>
                                  {showTitle}
                                  <Text
                                    value={editObject.planData[field.id]}
                                    onChange={(e) =>
                                      handleUpdate(field.id, e.target.value)
                                    }
                                  />
                                </TextInputWrapper>
                              );

                              return textWrapper(children);
                            })}
                          </Area>
                        );
                      })}
                    </>
                  );
                })}
              </>
            ) : (
              <Update planId={data.id} />
            )}
          </div>
        </Modal.Content>
      </Modal>
      <Modal
        basic
        onClose={() => setShowConfirmationModal(false)}
        onOpen={() => setShowConfirmationModal(true)}
        open={showConfirmationModal}
        size="small"
      >
        <Header icon>
          <Icon name="trash" />
          Are you sure you want to delete your action plan?
        </Header>
        <Modal.Content>
          <ModalDescriptionWrapper>
            <T2>
              Deleting your action plan will remove it forever, you may also
              archive it if you would like to view it later.
            </T2>
          </ModalDescriptionWrapper>
        </Modal.Content>
        <Modal.Actions>
          <ModalButtonContainer>
            <TextButton onClick={() => handleConfirmDelete()}>
              Delete
            </TextButton>
            <TextButton onClick={() => handleArchivePlan()}>Archive</TextButton>
            <TextButton onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </TextButton>
          </ModalButtonContainer>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Edit;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const ActionButtonWrapper = styled.div`
  cursor: "pointer";
  margin-left: 15px;
`;

const TopWrapper = styled.div`
  padding-top: 35px;
  width: "100%";
  display: flex;
  flex-direction: row;
`;

const TextButton = styled.div`
  margin: 25px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const CalIconWrapper = styled.div`
  padding-left: 100px;
  left: 35px;
`;

const TextInputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SportTextWrapper = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7e7e7e;
`;

const IconWrapper = styled.div`
  padding-right: 10px;
`;

const EditControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
`;

const TabsWrapper = styled.div`
  border-bottom: 0.4px;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;

  border-style: solid;
  border-color: #dfdfdf;
`;

const StatusDropdown = styled.div`
  border-top: 0.1px black solid;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 125px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 0.1px black solid;
  height: 100px;
  background-color: #fff;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 460px;
  height: 48px;

  border-radius: 99px;
`;

const StatusContainer = styled.div`
  flex-direction: column;
`;

const DatePickWrapper = styled.div`
  display: flex;
`;

const ModalDescriptionWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const Label = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  color: #6d6d6d;
  margin-top: 20px;
  width: 250px;
  line-height: 35px;
`;

const TitleWrapper = styled.div`
  flex-direction: row;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
`;

const Text = styled.textarea`
  width: 100%;
  height: 150px;

  color: #6d6d6d;
  padding: 10px;
  resize: none;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  background-color: #f8f8f8;
  margin-top: 10px;
`;

const TitleTop = styled.div`

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-right: 5px;
  line-height: 48px;
  width: 100px;
  /* identical to box height */

  color: #2A3039;
`;

const Top = styled.div`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-right: 24px;
  line-height: 48px;
  width: 140px;
  /* identical to box height */

  color: #6d6d6d;
`;
const T2 = styled.div`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  width: 116px;
  color: #6d6d6d;
`;

const T5 = styled.div`
  font-size: 14px;
  color: #7e7e7e;
  padding-top: 20px;
  padding-bottom: 10px;

  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
`;

const T1 = styled.div`

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  /* identical to box height */
  color: #476dfa;
`;

const Q = styled.div`
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;
  color: #2A3039;
`;

const Area = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextArea = styled.div`
  width: 100%;

  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
`;

const TopContainer2 = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
`;

const Dated = styled.div`
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9e9fa1;
`;

const RowApart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 55px;
`;

const R = styled.div`
  /* Auto layout */
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  width: 100%;
  border-radius: 99px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #9e9fa1;
`;

const Status = styled.div`
  padding: 1px;
  background-color: ${(props) => "rgba(" + props.color + ")"};
  border-radius: 30px;
  display: flex;
  width: 90px;
  height: 20px;
  width: fit-content;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`;

const StatusText = styled.div`
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  text-align: center;
`;

const StatusSelector = styled.div`
  padding: 5px 10px;

  border-left: 0.1px black solid;
  border-right: 0.1px black solid;
  background-color: ${(props) => "rgba(" + props.color + ",0.2)"};
  :hover {
    background-color: ${(props) => "rgba(" + props.color + ",0.4)"};
  }
  color: ${(props) => "rgba(" + props.color + ",1)"};
  font-weight: bold;
  font-size: 14px;
  border-bottom: ${(props) => (props.isLast ? "0.1px black solid" : "")};
  cursor: pointer;
  height: 50px;
`;

const planStatus = {
  Planned: {
    id: 1,
    statusText: "Planned",
    color: "71, 108, 250",
  },
  InProgress: {
    id: 2,
    statusText: "In Progress",
    color: "243, 171, 60",
  },
  ActionRequired: {
    id: 3,
    statusText: "Action Required",
    color: "230, 101, 121",
  },
  // OnHold: {
  //   id: 4,
  //   statusText: "On Hold",
  //   color: "209, 199, 2",
  // },
  Completed: {
    id: 5,
    statusText: "Completed",
    color: "102, 214, 134",
  },
  Archived: {
    id: 11,
    statusText: "Archived",
    color: "138, 138, 138",
  },
  Delete: {
    id: 99,
    statusText: "For Deletion",
    color: "138, 138, 138",
  },
};
