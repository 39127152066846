import React from "react";
import { View, Text, Image, StyleSheet, Page,Font } from "@react-pdf/renderer";
import Logo from "assets/images/pdf/smallLogo.png";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
// Create styles
const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100vw",
    padding: 20,
    paddingHorizontal: 40,
    fontSize: 8,
    color: "#2A3039",
    fontFamily: "Raleway",
  },
  line: {
    height: 0.2,
    width: "100%",
    backgroundColor: "grey",
    marginBottom: 10,
  },
});

const Footer = ({ overlapBackground }) => {
  return (
    <View
      style={[
        styles.footer,
        {
          // zIndex: overlapBackground ? 999 : -1,
          zIndex: -1,
          backgroundColor: overlapBackground ? "#F9F9F9" : "transparent",
        },
      ]}
      fixed
    >
      <View style={styles.line} />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <View style={{ flexDirection: "row" }}>
         
          <Text style={{ fontWeight: 600 }}>
            Nulogic DEI Report and Action Plans
          </Text>
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber - 1} / ${totalPages - 1}`
          }
        />
      </View>
    </View>
  );
};

Font.register({
    family: "Raleway",
    fonts: [{ src: Raleway }],
  });
  

export default Footer;
