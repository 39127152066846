import Logo from "assets/images/logo.png";
import FullLogo from "assets/images/logo_full.png";
import NuWave from "assets/images/nav/nuwave_black.png";
import OtpLogo from "assets/images/otp/Logo.png";

// SUB_DOMAIN_NAMES = [
//     (1, 'app.innerlogic.com'),  # game
//     (2, 'caatcanada.ca'),  # Specific Org
//     (3, 'app.nulogic.co'),  # External Partner
// ]

export const Domain = {
    id: 1,
    name: "Innerlogic",
    url: "app.innerlogic.com",
    image: Logo
}

export const DomainList = {
    "app.nulogic.co": {
        id: 3,
        name: "NuLogic",
        url: "app.nulogic.co",
        image: NuWave,
        fullImage: NuWave,
        loginText: "Login to access your nuLogic account",
        allowRegister: true,
        registerText: "Donot have a login",
        notRegisterText: "",
        showGoogleSso: false,
        debug: false,
        homePage: "https://www.nuwaveco.com/",
        privacyPolicy: "https://innerlogic.com/privacy/",
        googleClientId: '',
        googleScope: "https://www.googleapis.com/auth/userinfo.email",
    }
}