import styled from "styled-components";
import React from "react";
import planningTemplates from "../templates/planningTemplates.json";

const Plan = ({ data, setData }) => {
  const setValue = (id, text) => {
    const _data = Object.assign({}, data);
    _data.planData[id] = text;
    setData(_data);
  };

  const questionSections =
    planningTemplates[data.plan].planTemplate.planSections;

  return (
    <Container>
      <Top>DEI Pillar </Top>
      <Question>{data.metric.title}</Question>
      {questionSections.map((section, s) => {
        return (
          <>
            <T1>{section.sectionTitle}</T1>
            {section.questions.map((question, i) => {
              return (
                <Area>
                  <Q>{question.question}</Q>
                  {question.inputFields.map((field, f) => {
                    // Show or hide title if it exists.
                    const showTitle = field.title.length > 0 && (
                      <T2>{field.title}</T2>
                    );

                    // Function to handle conditional wrapping of textarea
                    const textWrapper = (children) => {
                      if (showTitle) {
                        return <TextArea>{children}</TextArea>;
                      }
                      return <>{children}</>;
                    };

                    // Children elements
                    const children = (
                      <TextWrapper>
                        {showTitle}
                        <Text
                          placeholder={field.placeholder}
                          value={data.planData[field.id]}
                          onChange={(e) => setValue(field.id, e.target.value)}
                        />
                      </TextWrapper>
                    );
                    return textWrapper(children);
                  })}
                </Area>
              );
            })}
          </>
        );
      })}
    </Container>
  );
};

export default Plan;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  font-family: "Barlow", sans-serif;
  margin-bottom: 60px;
`;

const Top = styled.div`
  font-size: 12px;
  font-family: "Poppins";
  font-style: normal;
  color: lightgrey;
  text-transform: uppercase;
`;

const Title = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
`;

const T1 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #476dfa;
  margin-bottom: 20px;
`;

const TextWrapper = styled.div`
  flex-direction: row;
  width: 100%;
`;

const T2 = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7e7e7e;

  margin-bottom: 5px;

  margin-top: 10px;
`;

const Q = styled.div`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7e7e7e;

  margin-right: 20px;
`;

const Text = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7e7e7e;

  padding: 15px;
  resize: none;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  margin-top: 10px;
`;

const Text2 = styled.textarea`
  width: 100%;
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
  border-radius: 3px;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
`;

const Text3 = styled.textarea`
  width: 100%;
  height: 30px;
  font-family: "Barlow", sans-serif;
  padding: 5px;
  margin-bottom: 20px;
`;

const Area = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Question = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;

  margin-bottom: 30px;
`;

const TextArea = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #7e7e7e;
  padding-top: 15px;
  padding-bottom: 15px;

  border-radius: 30px;
  width: 100%;
`;
